@keyframes App-logo-spin {
    from {
		transform: rotate(0deg);
    }

    to {
		transform: rotate(360deg);
    }
}

.App-logo {
    height: 30vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
		animation: App-logo-spin infinite 20s linear;
    }
}

form.Login {
    display: flex;
    flex-direction: column;
}

.registerLink {
    font-size: 0.5em;
}

@media only screen and (max-width: 600px) {
	.App-logo {
		height: 50vmin;
	}

	form.Login {
		font-size: 24px;
	}

	form.Login input {
		font-size: 24px;
	}

	form.Login button {
		font-size: 24px;
	}

	.registerLink {
		font-size: 18px;
	}
}