.NavBar {
    width: 100%;
    background-color: var(--green-color);
}

.NavBar div {
    max-width: 1200px;
    height: 75px;
    margin: auto;
    text-align: left;
}

.NavBar a {
    display: inline-block;
    padding: 0 25px;
    font-size: 25px;
    line-height: 75px;
    vertical-align: middle;
    color: white;
    text-decoration: none;
}

.NavBar a.Current {
    background-color: var(--green-dark);
}

.NavBar a:hover {
    background-color: var(--green-dark);
}

.NavBar a.Right {
    float: right;
}

@media only screen and (max-width: 600px) {
	.NavBar div {
		height: 50px;
	}

	.NavBar a {
		height: 50px;
		line-height: 50px;
		padding: 0 10px;
		font-size: 18px;
	}
}