div.ButtonAdd {
    background-color: var(--primary-dark);
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    text-align: center;
    font-size: 60px;
    font-weight: 900;
    color: white;
    border-radius: 25px;
    margin: 8px 8px 8px auto;
}

div.ButtonAdd p {
    margin: 0;
    line-height: 35px;
}
