.App {
	position: relative;
    text-align: center;
    font-size: calc(10px + 2vmin);
    color: black;
}

.User {
    max-width: 1200px;
    margin: auto;
}

.App-header {
    min-height: calc(100vh - 175px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
}

@keyframes App-logo-spin {
    from {
	transform: rotate(0deg);
    }

    to {
	transform: rotate(360deg);
    }
}

.App-logo {
    height: 30vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
	animation: App-logo-spin infinite 20s linear;
    }
}

.App-link {
    color: #61dafb;
}

.ErrorMessage {
    text-align: center;
    color: white;
}

.ErrorMessage h2 {
    margin: 0;
}