.CompetitionPage {
    text-align: left;
	padding: 0 10px;
}

.CompetitionPage .Top {
    height: 75px;
    margin: 30px 0;
    display: flex;
}

.CompetitionPage .Top > div {
    width: 75px;
    height: 75px;
    text-align: center;
}

.CompetitionPage .Top img{
    max-width: 75px;
    max-height: 75px;
    width: auto;
    height: auto;
}

.CompetitionPage .Top h1 {
    margin: 0 40px;
    display: flex;
    align-items: center;
    color: var(--primary-dark);
}

.CompetitionPage .Content {
    display: flex;
	gap: 20px;
}

.CompetitionPage h2 {
    margin: 0;
    text-align: center;
    font-weight: lighter;
}

.CompetitionPage .Column {
    width: 600px;
	min-width: 600px;
    display: flex;
    flex-direction: column;
}

.CompetitionPage .Column:last-of-type {
	min-width: auto
}

.CompetitionPage .Filter {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
	flex-wrap: wrap;
	gap: 20px;
}

.CompetitionPage .Filter button {
	width: calc(100% / 3 - 20px);
	color: white;
	background-color: var(--primary-light);
	font-size: 32px;
	border: none;
	border-radius: 10px;
	padding: 10px;
}

.CompetitionPage .Filter button.Active {
	background-color: green;
}

.CompetitionPage .Filter button.Pending {
	background-color: red;
}

.Collapsible .Item td.Name {
	width: 420px;
	text-align: left;
}

.Collapsible .Item td {
	width: 60px;
	text-align: right;
}

.CompetitionPage table td {
	text-align: center;
	padding: 0 4px;
}

.CompetitionPage table td.Name {
	width: 100%;
	text-align: left;
}

.CompetitionPage table td.Rank {
	text-align: left;
}

@media only screen and (max-width: 1065px) {
	.CompetitionPage .Content {
		flex-direction: column-reverse;
	}

	.CompetitionPage .Column {
		width: 100%;
		min-width: unset;
		margin: 0 !important;
	}

	.CompetitionPage .Top > div {
		display: none;
	}

	.CompetitionPage .Top h1 {
		width: 100%;
		margin: 0;
		text-align: center;
		display: block;
	}

	.CompetitionPage .Filter {
		flex-direction: column;
	}

	.CompetitionPage .Filter button {
		width: 100%;
	}
}