.PopUp-background { 
    background-color: rgb(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
}

.PopUp {
    max-width: 300px;
    background-color: var(--primary-light);
    border: 8px solid var(--primary-dark);
    border-radius: 8px;
    padding: 8px;
    z-index: 1000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}