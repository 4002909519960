.Gallery {
    max-width: 540px;
    width: fit-content;
    margin: auto;
    margin-top: 10px;
    padding: 10px;
    background-color: var(--primary-dark);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.Gallery > div {
    width: 100px;
    height: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}