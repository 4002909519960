.AddMenu button {
    height: 25px;
    font: 16px "Century Gothic", Futura, sans-serif;
    border: 2px solid;
    border-radius: 8px;
}

.AddMenu .Image {
    width: 100px;
    height: 100px;
    margin: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.AddMenu input[type=checkbox] {
    width: 25px;
    height: 25px;
}

.AddMenu label {
    display: flex;
    align-items: center;
}

.codeLabel {
    font-family: monospace;
    font-weight: bold;
    background: #ccc;
    padding: 0 5px;
    border: 1px solid #666;
    border-radius: 4px;
}