.HomePage {
	padding: 0 10px;
}

.HomePage .Column-wrapper {
    margin: 20px auto;
    width: 1000px;
}

.HomePage .Column {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    padding: 8px 4px;
}

.HomePage .Column.Competitions {
    width: 20%;
    float: left;
}

.HomePage .Row {
    background-color: var(--primary-light);
    border: 8px solid var(--primary-dark);
    border-radius: 8px;
    margin-bottom: 8px;
}

.HomePage .Row.HasScore {
    background-color: var(--green-color);
    border: 8px solid var(--green-dark);
}

.HomePage .Popup-item {
    width: 50%;
    display: flex;
    flex-direction: column;
    float: left;
}

.HomePage .Popup-item:last-child {
    float: right;
}

.HomePage .Popup-item input {
    width: 50%;
    margin: 8px auto 0;
}