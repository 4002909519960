.AdminPage h1 {
    margin: 0;
}

.AdminPage {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
}

.AdminPage .Column-wrapper {
    display: flex;
    height: calc(100vh - 76px - 75px);
}

.AdminPage .Column {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 8px 4px;
}

.AdminPage .Column.Menu{
    max-width: 20%;
    min-width: 300px;
}

.AdminPage .Column:first-of-type {
    padding-left: 8px;
}

.AdminPage .Column:last-of-type {
    padding-right: 8px;
}

.AdminPage .Column .Block {
    height: 100%;
    background-color: var(--primary-light);
    border: 8px solid var(--primary-dark);
    border-radius: 8px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}

.AdminPage .Column .Block:last-of-type {
    margin-bottom: 0;
}

.AdminPage .Column .Block h1 {
    font-size: 30px;
    margin: 12px;
}

.AdminPage .Column .Block .List {
    height: 100%;
    overflow-y: auto;
    padding: 8px;
}

.AdminPage .Column .Block .List .Item {
    background-color: var(--primary-light);
    border: 8px solid var(--primary-dark);
    border-radius: 8px;
    margin-bottom: 8px;
    font-size: 20px;
    cursor: pointer;
}

.AdminPage .Column .Block .List .Item.Selected {
    box-shadow: 0px 0px 8px 0px #FFF;
}

.AdminPage .Column .Block .List .Item.Finished {
    background-color: var(--green-color);
    border: 8px solid var(--green-dark);
}

.AdminPage .Column .Block .List .Item:last-of-type {
    margin-bottom: 0;
}