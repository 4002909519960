.RegisterPage {
    min-height: calc(100vh - 175px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.registerButton {
    margin: 20px;
}