:root {
    --primary-color: #0B438C;
    --primary-light: #1c7eff;
    --primary-dark: #0b438c;
    --green-color: #13842b;
    --green-dark: #0f6722;
    --background: #EEFFDD;
    --banner: #222222
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--background);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root > div > .PopUp-background {
    z-index: 1000;
}

button {
    cursor: pointer;
}

.NoSelect {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.Hidden {
    display: none !important;
}
