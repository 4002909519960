.MatchCard {
    text-align: left;
    padding-top: 40px;
    padding-bottom: 15px;
	position: relative;
}

.MatchCard > p {
    margin:  0 0 5px 25px;
    font-size: 16px;
}

.MatchCard .Container {
    display: flex;
}

.MatchCard .Card {
    width: 600px;
    height: 180px;
    background-color: var(--primary-dark);
    border-radius: 20px;
    box-shadow: 10px 10px 10px 0px rgba(0,0,0,0.5);
    position: relative;
}

.MatchCard .Card .Wrapper {
    width: 140px;
    margin: 0 5px;
    position: absolute;
    top: 20px;
    z-index: 1;
}

.MatchCard .Card .Wrapper.Home {
    left: 0px;
}

.MatchCard .Card .Wrapper.Out {
    right: 0px;
}

.MatchCard .Card .Wrapper > div {
    width: 100px;
    height: 100px;
    margin: 0 15px;
    background-color: var(--primary-light);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
    border: 5px solid var(--primary-light);
    text-align: center;
}

.MatchCard .Card p {
	width: 100%;
    color: white;
    text-align: center;
    font-size: 15px;
    margin: 0;
    position: absolute;
    top: 115px;
}

.MatchCard .Card .Line {
    width: 450px;
    height: 75px;
    background-color: var(--primary-light);
    position: absolute;
    top: 37.5px;
    left: 75px;
}

.MatchCard .Card .Background {
    width: 150px;
    height: 65px;
    background-color: black;
    border-radius: 10px;
    position: absolute;
    top: 5px;
}

.MatchCard .Card .Background.Out {
    right: 0;
}

.MatchCard .Card input {
    width: 85px;
    padding: 0;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    line-height: 55px;
    color: white;
    background-color: transparent;
    border: none;
    outline: none;
    position: absolute;
    top: 5px;
}

.MatchCard .Card .Home input {
    right: 5px;
}

.MatchCard .Card .Out input {
    left: 5px;
}

.MatchCard .Card input::-webkit-outer-spin-button,
.MatchCard .Card input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.MatchCard .Card input[type=number] {
    -moz-appearance: textfield;
}

.MatchCard .Card .Checkmark {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    border: 5px solid white;
    background-color: #FF8A00;
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
}

.MatchCard .Card .Checkmark.Done {
    background-color: var(--green-color);
}

.MatchCard .Card button {
    width: 130px;
    height: 40px;
    border: none;
    border-radius: 10px;
    font-size: 15px;
    padding: 0;
    color: white;
    background-color: var(--green-color);
    position: absolute;
    top: 125px;
    left: 50%;
    transform: translateX(-50%);
}

.MatchCard .Card button:hover {
    background-color: var(--green-dark);
}

.MatchCard .Competition {
    min-width: 200px;
    margin: 25px 0;
    margin-left: -25px;
    padding: 10px 10px;
    padding-left: 35px;
    box-sizing: border-box;
    box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.5);
    background-color: var(--primary-light);
    border-radius: 20px;
}

.MatchCard .Competition a {
    height: 100%;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.MatchCard .Competition img {
    max-width: 75px;
    max-height: 75px;
    width: auto;
    height: auto;
}

.MatchCard .Competition p {
    margin: 0;
    color: white;
    font-size: 16px;
    text-align: center;
}

.MatchCard .Card.Gray {
    filter: grayscale(1);
}

.MatchCard .Card.Playing {
	background-color: darkgreen;
}

.MatchCard > div {
    position: relative;
    width: 600px;
}

.MatchCard .Result {
    width: 100px;
    height: 100px;
    background-color: var(--primary-dark);
    border-radius: 50%;
    border: 5px solid var(--primary-light);
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
}

.MatchCard .Result p {
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    color: white;
}

.CompetitionPage .MatchCard .Card {
    cursor: pointer;
}

.CompetitionPage .Collapsible {
	color: white;
	background-color: var(--primary-light);
	border-radius: 0 0 20px 20px;
	padding-top: 75px;
	margin-top: -35px;
	cursor: pointer;
}

.MatchCard h2 {
	display: none;
}

.CompetitionPage .MatchCard h2 {
	display: block;
	position: absolute;
	width: 100%;
	color: white;
	font-size: 36px;
	font-weight: 400;
	bottom: -36px;
	left: 50%;
	transform: translateX(-50%);
	cursor: pointer;
}

.CompetitionPage .Collapsible td {
	text-wrap: nowrap;
}

@media only screen and (max-width: 600px) {
	.MatchCard {
		padding-top: 10px;
	}

	.MatchCard > div {
		width: 100%;
	}

	.MatchCard .Card {
		width: calc(100vw - 20px);
		height: 140px;
		box-shadow: none;
	}

	.MatchCard .Card .Wrapper {
		width: 70px;
	}

	.MatchCard .Card .Wrapper > div {
		width: 60px;
		height: 60px;
		margin: 0;
	}

	.MatchCard .Result {
		width: 60px;
		height: 60px;
	}

	.MatchCard .Result p {
		font-size: 20px;
	}

	.MatchCard .Result p {
		margin: 0;
		line-height: 60px;
	}

	.MatchCard .Card .Line {
		width: calc(100vw - 100px);
		height: 60px;
		top: 25px;
		left: 40px;
	}

	.MatchCard .Card .Line .Background {
		width: 90px;
		height: 50px;
	}

	.MatchCard .Card .Line .Background input {
		width: 60px;
		line-height: 40px;
	}

	.MatchCard .Card .Line .Checkmark {
		width: 40px;
		height: 40px;
	}

	.MatchCard .Card p {
		width: 200%;
		top: 84.5px;
	}

	.MatchCard .Card .Home p {
		left: 10px;
		text-align: left;
	}

	.MatchCard .Card .Out p {
		right: 10px;
		text-align: right;
	}

	.MatchCard .Card button {
		top: 92.5px;
	}

	.MatchCard .Container {
		flex-direction: column;
	}

	.MatchCard .Competition {
		width: calc(100vw - 20px);
		min-width: 0px;
		margin: 10px 0;
		padding: 5px 0;
		box-shadow: none;
	}

	.CompetitionPage .MatchCard h2 {
		font-size: 24px;
		bottom: -28px;
	}
}