.Banner {
    width: 100%;
    background-color: var(--banner);
	position: relative;
}

.Banner div {
    max-width: 1200px;
    height: 100px;
    margin: auto;
    font-size: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.Banner img {
    height: 60px;
    object-fit: fill;
    margin-right: 20px;
}

.Banner span {
	font-size: 16px;
	position: absolute;
	bottom: 1px;
	right: 4px;
}

@media only screen and (max-width: 600px) {
	.Banner div {
		height: 50px;
		font-size: 25px;
	}

	.Banner img {
		display: none;
	}
}