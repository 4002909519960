.CompetitionsListPage {
    text-align: left;
    display: flex;
    flex-direction: column;
	padding: 0 10px;
}

.CompetitionsListPage .Row {
    padding-bottom: 20px;
}

.CompetitionsListPage .Item {
    width: 400px;
    height: 75px;
    padding: 10px 10px;
    color: white;
    background-color: var(--green-color);
    text-decoration: none;
    border-radius: 20px;
    box-shadow: 10px 10px 10px 0px rgba(0,0,0,0.5);
    box-sizing: border-box;
    display: flex;
}

.CompetitionsListPage .Item.Code {
    background-color: var(--primary-dark);
}

.CompetitionsListPage .Item input {
    width: 100%;
    border: none;
    border-radius: 10px;
    font-weight: bold;
    font-size: 30px;
}

.CompetitionsListPage .Item button {
    margin-left: 10px;
    color: white;
    background-color: var(--green-color);
    border: none;
    border-radius: 10px;
    font-weight: bold;
    font-size: 30px;
}

.CompetitionsListPage .Item button:hover {
    background-color: var(--green-dark);
}

.CompetitionsListPage .Item p {
    margin: 0 20px;
    margin-left: 30px;
    font-size: 22px;
    line-height: 100%;
    display: flex;
    align-items: center;
}

.CompetitionsListPage .Item > div {
    width: 55px;
    height: 55px;
    text-align: center;
}

.CompetitionsListPage .Item img {
    max-width: 55px;
    max-height: 55px;
    width: auto;
    height: auto;
}

.CompetitionsListPage h3 {
	margin: 0;
	font-size: 30px;
	text-align: center;
	width: 400px;
}

@media only screen and (max-width: 600px) {
	.CompetitionsListPage .Item {
		width: 100%;
		box-shadow: none;
	}

	.CompetitionsListPage h1 {
		text-align: center;
	}
}